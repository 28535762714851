import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  input,
  output,
  viewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfiniteScrollDirective} from "../../../core/directives/infinite-scroll.directive";

@Component({
    selector: 'sc-side-panel',
    imports: [CommonModule, InfiniteScrollDirective],
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidePanelComponent implements AfterViewInit {

  readonly sidePanel = viewChild<ElementRef>('sidePanel');
  readonly title = input<string>('');
  readonly direction = input<'leftToRight' | 'rightToLeft'>('leftToRight');
  readonly width = input<string>('w-full');
  readonly classes = input<string>('');
  readonly onClose = output<void>();
  readonly infiniteScroll = output<void>();
  isPanelOpened: boolean = false;
  readonly closeTimeout: number = 200;


  constructor() {
  }

  @Input() set show(show: boolean) {
    this.isPanelOpened = show;
    if (show) {
      this.sidePanel()?.nativeElement?.classList.remove(['!bg-transparent', '!border-transparent']);
    }
  };

  ngAfterViewInit(): void {
    const sidePanel = this.sidePanel();
    const height = sidePanel?.nativeElement.parentElement?.parentElement?.offsetHeight;
    if (height) sidePanel?.nativeElement?.setAttribute('style', `height: ${height}px;`);
  }

  closePanel() {
    this.isPanelOpened = false;
    setTimeout(() => {
      this.sidePanel()?.nativeElement?.classList.add(['!bg-transparent', '!border-transparent']);
    }, this.closeTimeout)
    this.onClose.emit();
  }

  test($event: any) {

  }

  endOfListHandler() {
    this.infiniteScroll.emit();
  }
}
